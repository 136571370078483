html {
    height: 100%;
    width: 100%;
}

body {
    align-items: center;
    background: #081c36;
    box-sizing: border-box;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem 2rem 3rem;
    align-items: start;
    max-width: 1200px;
    grid-template-areas:
        "header"
        "sidebar"
        "content"
        "footer"
    ;

    @include for-tablet-landscape-up {
        display: grid;
        grid-column-gap: 4.8rem;
        grid-row-gap: 1.6rem;
        grid-template-columns: min-content 1fr;
        grid-template-areas:
            "sidebar header"
            "sidebar content"
            "sidebar footer"
        ;
        padding: 4rem;
    }
}

main {
    grid-area: content;
    margin-bottom: 1.6rem;
}

#sidebar {
    grid-area: sidebar;

    @include for-tablet-landscape-up {
        grid-row: 1 / span 2;
        position: sticky;
    }
}

footer {
    grid-area: footer;
}
