nav {
    background: inherit;
    box-shadow: 0 10px 40px 0 #081c36;
    grid-area: header;
    overflow-x: hidden;
    text-align: center;

    @include for-tablet-portrait-up {
        display: inline-block;
        overflow: hidden;
        position: sticky;
        top: 0;
        width: 100%;
    }

    [data-navigation-toggle] {
        height: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 3.9rem;
        top: 2.3rem;
        width: 0;
        visibility: hidden;
        z-index: 2;

        &:after {
            background-color: #6fa8dc;
            content: '';
            cursor: pointer;
            display: block;
            mask: url('../images/bigmac.svg');
            height: 1.6rem;
            right: 0;
            top: 0;
            width: 1.6rem;
            visibility: visible;
        }

        @include for-tablet-portrait-up {
            display: none;
        }

        &:checked {
            position: fixed;

            &:after {
                background-color: #f1c232;
            }

            & + ul {
                transform: translateX(0);
            }
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;

        list-style: none;
        margin: 0;
        padding: 0;
        //flex-wrap: nowrap;

        background: black;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        z-index: 1;

        @include for-tablet-portrait-up {
            display: inline;
            background: inherit;
            float: right;
            position: initial;
            transform: translateX(0);
        }

        li {
            //float: left;
            padding: 1rem;

            @include for-tablet-portrait-up {
                display: inline-block;
            }
        }
    }
}
