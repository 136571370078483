#sidebar {
    align-items: center;
    display: flex;
    margin-bottom: 1.6rem;
    text-align: center;
    top: 0;

    @include for-tablet-portrait-up {
        display: initial;
    }

    h1 {
        font-size: 1.6rem;
        line-height: 1.4;
        margin-bottom: 0;

        @include for-tablet-portrait-up {
            font-size: 2.2rem;
        }
    }

    h2 {
        display: none;
        line-height: 1.4;

        @include for-tablet-portrait-up {
            display: block;
        }
    }

    .profilePic {
        border: 0px solid #fff;
        border-radius: 50%;
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.38);
        display: block;
        margin: 0 0.8rem 0 0;
        position: relative;
        height: 2.2rem;
        width: 2.2rem;

        @include for-tablet-portrait-up {
            height: 200px;
            margin: 1.6rem auto;
            width: 200px;
        }

        img {
            border-radius: inherit;
            display: block;
            width: 100%;

            &::after {
                backdrop: red;
                border-radius: inherit;
                box-shadow: inset 0px 0px 40px -10px rgba(0,0,0,0.9);
                content: '';
                display: block;
                height: inherit;
                pointer-events: none;
                position: absolute;
                top: 0;
                width: inherit;
            }
        }
    }

    .social {
        display: none;
        list-style: none;
        grid-area: social;
        margin: 0 0 1.6rem 0;
        padding: 0;

        @include for-tablet-portrait-up {
            display: block;
        }

        li {
            display: inline-flex;
            margin-bottom: 0.8rem;

            a {
                flex: 1;
                font-size: 0;
                padding-left: 2.4rem;
                position: relative;

                &:before {
                    background-color: #6fa8dc;
                    content: '';
                    display: inline-block;
                    height: 1.6rem;
                    left: 0;
                    transition: 0.25s background-color;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1.6rem;
                }

                &:focus:before,
                &:hover:before {
                    background: #f1c232;
                }

                &[href*="github"]:before {
                    mask: url('../images/github.svg');
                }
                &[href*="twitter"]:before {
                    mask: url('../images/twitter.svg');
                }
                &[href*="dev"]:before {
                    mask: url('../images/dev-dot-to.svg');
                }
                &[href*="contact"]:before {
                    mask: url('../images/email.svg');
                }
            }
        }
    }
}
