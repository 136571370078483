button,
input,
select,
textarea {
    border: 2px solid transparent;
    border-radius: 0.15rem;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    height: 2.8rem;
    line-height: 2.8;
    outline: 0;
    margin-top: 0.8rem;
    padding: 0 1rem;
    width: 100%;

    &:focus {
        border-color: #f1c232;
    }
}

button {
    background: #6fa8dc;
    border: 0;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    margin: 0;
    transition: 0.25s background-color;
    width: auto;

    &:focus,
    &:hover {
        background: #f1c232;
    }

    &[type="reset"] {
        background: none;
        color: #6fa8dc;
        text-decoration: underline;

        &:focus,
        &:hover {
            color: #f1c232;
        }
    }
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    text-align: left;
}

form {
    text-align: right;

    @include for-tablet-portrait-up {
        margin: 0 auto;
        width: 80%;
    }

    #errorMessage {
        color: #db6f6f;
        display: block;
        text-align: center;

        &:empty {
            display: none;
        }
    }
}

label {
    color: #6fa8dc;
    display: block;
    margin-bottom: 1.6rem;

    &.error {
        color: #db6f6f;

        input,
        select,
        textarea {
            border-color: #db6f6f;
        }
    }
}

legend {
    display: none;
}

textarea {
    line-height: 1.4;
    min-height: 1.4 * 12 + rem;
    padding: 1rem;
    resize: vertical;
}
