body {
    color: #fff;
    font: 16px/1.6 Rubik;
}

a {
    &:active,
    &:link,
    &:visited {
        color: #6fa8dc;
        text-decoration: underline;
    }

    &:focus,
    &:hover {
        color: #f1c232;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    margin: 0 0 1.6rem 0;

    &.secondary {
        color: #6fa8dc;
    }

    &.tertiary {
        border-left: 2px solid currentColor;
        color: #f1c232;
        padding-left: 1rem;
    }
}

p {
    margin: 0 0 1.6rem 0;
}
