#portfolio {
    margin: 0;
    padding: 0;

    li {
        display: grid;
        grid-column-gap: 1.6rem;
        grid-row-gap: 1.6rem;
        grid-template-areas:
            "title"
            "image"
            "data"
            "copy"
            "link"
        ;

        &:not(:last-child) {
            border-bottom: 1px solid lighten(#081c36, 10%);
            margin-bottom: 3.2rem;
            padding-bottom: 3.2rem;
        }

        @include for-tablet-portrait-up {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "title image"
                "data image"
                "data image"
                "copy copy"
                ". link"
            ;
        }

        @include for-tablet-landscape-up {
            grid-template-columns: 1fr;
            grid-template-areas:
                "title"
                "image"
                "data"
                "copy"
                "link"
            ;
        }

        @include for-desktop-up {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "title image"
                "data image"
                "data image"
                "copy copy"
                ". link"
            ;
        }

        a {
            grid-area: link;
            text-align: right;
        }

        img {
            border-radius: 0.4rem;
            grid-area: image;
            width: 100%;
        }

        dl {
            grid-area: data;
            margin: 0;
            width: 100%;

            dd {
                margin-left: 1.6rem;
            }

            dt {
                color: #ccc;
            }
        }

        h3 {
            grid-area: title;
            margin: 0;
        }

        p {
            grid-area: copy;
            margin-bottom: 0;
        }
    }
}
