details {
    margin-bottom: 1.6em;

    summary {
        color: #ccc;
        cursor: pointer;
        list-style: none;
        font-style: italic;
        margin-bottom: 0.8rem;

        &::-webkit-details-marker {
            display: none;
        }

        time {
            display: block;

            @include for-tablet-portrait-up {
                float: right;
            }
        }
    }
}
