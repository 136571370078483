body {
  color: #fff;
  font: 16px/1.6 Rubik;
}

a:active, a:link, a:visited {
  color: #6fa8dc;
  text-decoration: underline;
}

a:focus, a:hover {
  color: #f1c232;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0 0 1.6rem 0;
}

h1.secondary,
h2.secondary,
h3.secondary,
h4.secondary,
h5.secondary,
h6.secondary {
  color: #6fa8dc;
}

h1.tertiary,
h2.tertiary,
h3.tertiary,
h4.tertiary,
h5.tertiary,
h6.tertiary {
  border-left: 2px solid currentColor;
  color: #f1c232;
  padding-left: 1rem;
}

p {
  margin: 0 0 1.6rem 0;
}

html {
  height: 100%;
  width: 100%;
}

body {
  align-items: center;
  background: #081c36;
  box-sizing: border-box;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem 2rem 3rem;
  align-items: start;
  max-width: 1200px;
  grid-template-areas: "header" "sidebar" "content" "footer";
}

@media (min-width: 900px) {
  body {
    display: grid;
    grid-column-gap: 4.8rem;
    grid-row-gap: 1.6rem;
    grid-template-columns: -webkit-min-content 1fr;
    grid-template-columns: min-content 1fr;
    grid-template-areas: "sidebar header" "sidebar content" "sidebar footer";
    padding: 4rem;
  }
}

main {
  grid-area: content;
  margin-bottom: 1.6rem;
}

#sidebar {
  grid-area: sidebar;
}

@media (min-width: 900px) {
  #sidebar {
    grid-row: 1/span 2;
    position: -webkit-sticky;
    position: sticky;
  }
}

footer {
  grid-area: footer;
}

button, #banner,
input,
select,
textarea {
  border: 2px solid transparent;
  border-radius: 0.15rem;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  height: 2.8rem;
  line-height: 2.8;
  outline: 0;
  margin-top: 0.8rem;
  padding: 0 1rem;
  width: 100%;
}

button:focus, #banner:focus,
input:focus,
select:focus,
textarea:focus {
  border-color: #f1c232;
}

button, #banner {
  background: #6fa8dc;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin: 0;
  transition: 0.25s background-color;
  width: auto;
}

button:focus, #banner:focus, button:hover, #banner:hover {
  background: #f1c232;
}

button[type=reset], [type=reset]#banner {
  background: none;
  color: #6fa8dc;
  text-decoration: underline;
}

button[type=reset]:focus, [type=reset]#banner:focus, button[type=reset]:hover, [type=reset]#banner:hover {
  color: #f1c232;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  text-align: left;
}

form {
  text-align: right;
}

@media (min-width: 600px) {
  form {
    margin: 0 auto;
    width: 80%;
  }
}

form #errorMessage {
  color: #db6f6f;
  display: block;
  text-align: center;
}

form #errorMessage:empty {
  display: none;
}

label {
  color: #6fa8dc;
  display: block;
  margin-bottom: 1.6rem;
}

label.error {
  color: #db6f6f;
}

label.error input,
label.error select,
label.error textarea {
  border-color: #db6f6f;
}

legend {
  display: none;
}

textarea {
  line-height: 1.4;
  min-height: 16.8rem;
  padding: 1rem;
  resize: vertical;
}

footer {
  border-top: 1px solid #0f3362;
  font-size: 0.85rem;
  padding-top: 1.6rem;
  text-align: center;
}

footer span {
  color: #f1c232;
}

nav {
  background: inherit;
  box-shadow: 0 10px 40px 0 #081c36;
  grid-area: header;
  overflow-x: hidden;
  text-align: center;
}

@media (min-width: 600px) {
  nav {
    display: inline-block;
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
  }
}

nav [data-navigation-toggle] {
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 3.9rem;
  top: 2.3rem;
  width: 0;
  visibility: hidden;
  z-index: 2;
}

nav [data-navigation-toggle]:after {
  background-color: #6fa8dc;
  content: "";
  cursor: pointer;
  display: block;
  -webkit-mask: url("bigmac.998fbc8b.svg");
          mask: url("bigmac.998fbc8b.svg");
  height: 1.6rem;
  right: 0;
  top: 0;
  width: 1.6rem;
  visibility: visible;
}

@media (min-width: 600px) {
  nav [data-navigation-toggle] {
    display: none;
  }
}

nav [data-navigation-toggle]:checked {
  position: fixed;
}

nav [data-navigation-toggle]:checked:after {
  background-color: #f1c232;
}

nav [data-navigation-toggle]:checked + ul {
  transform: translateX(0);
}

nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  background: black;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: 1;
}

@media (min-width: 600px) {
  nav ul {
    display: inline;
    background: inherit;
    float: right;
    position: initial;
    transform: translateX(0);
  }
}

nav ul li {
  padding: 1rem;
}

@media (min-width: 600px) {
  nav ul li {
    display: inline-block;
  }
}

#sidebar {
  align-items: center;
  display: flex;
  margin-bottom: 1.6rem;
  text-align: center;
  top: 0;
}

@media (min-width: 600px) {
  #sidebar {
    display: initial;
  }
}

#sidebar h1 {
  font-size: 1.6rem;
  line-height: 1.4;
  margin-bottom: 0;
}

@media (min-width: 600px) {
  #sidebar h1 {
    font-size: 2.2rem;
  }
}

#sidebar h2 {
  display: none;
  line-height: 1.4;
}

@media (min-width: 600px) {
  #sidebar h2 {
    display: block;
  }
}

#sidebar .profilePic {
  border: 0px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.38);
  display: block;
  margin: 0 0.8rem 0 0;
  position: relative;
  height: 2.2rem;
  width: 2.2rem;
}

@media (min-width: 600px) {
  #sidebar .profilePic {
    height: 200px;
    margin: 1.6rem auto;
    width: 200px;
  }
}

#sidebar .profilePic img {
  border-radius: inherit;
  display: block;
  width: 100%;
}

#sidebar .profilePic img::after {
  backdrop: red;
  border-radius: inherit;
  box-shadow: inset 0px 0px 40px -10px rgba(0, 0, 0, 0.9);
  content: "";
  display: block;
  height: inherit;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: inherit;
}

#sidebar .social {
  display: none;
  list-style: none;
  grid-area: social;
  margin: 0 0 1.6rem 0;
  padding: 0;
}

@media (min-width: 600px) {
  #sidebar .social {
    display: block;
  }
}

#sidebar .social li {
  display: inline-flex;
  margin-bottom: 0.8rem;
}

#sidebar .social li a {
  flex: 1;
  font-size: 0;
  padding-left: 2.4rem;
  position: relative;
}

#sidebar .social li a:before {
  background-color: #6fa8dc;
  content: "";
  display: inline-block;
  height: 1.6rem;
  left: 0;
  transition: 0.25s background-color;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.6rem;
}

#sidebar .social li a:focus:before, #sidebar .social li a:hover:before {
  background: #f1c232;
}

#sidebar .social li a[href*=github]:before {
  -webkit-mask: url("github.e460e458.svg");
          mask: url("github.e460e458.svg");
}

#sidebar .social li a[href*=twitter]:before {
  -webkit-mask: url("twitter.fe9dd608.svg");
          mask: url("twitter.fe9dd608.svg");
}

#sidebar .social li a[href*=dev]:before {
  -webkit-mask: url("dev-dot-to.4c6a4278.svg");
          mask: url("dev-dot-to.4c6a4278.svg");
}

#sidebar .social li a[href*=contact]:before {
  -webkit-mask: url("email.a88b6cd7.svg");
          mask: url("email.a88b6cd7.svg");
}

details {
  margin-bottom: 1.6em;
}

details summary {
  color: #ccc;
  cursor: pointer;
  list-style: none;
  font-style: italic;
  margin-bottom: 0.8rem;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary time {
  display: block;
}

@media (min-width: 600px) {
  details summary time {
    float: right;
  }
}

#portfolio {
  margin: 0;
  padding: 0;
}

#portfolio li {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-row-gap: 1.6rem;
  grid-template-areas: "title" "image" "data" "copy" "link";
}

#portfolio li:not(:last-child) {
  border-bottom: 1px solid #0f3362;
  margin-bottom: 3.2rem;
  padding-bottom: 3.2rem;
}

@media (min-width: 600px) {
  #portfolio li {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title image" "data image" "data image" "copy copy" ". link";
  }
}

@media (min-width: 900px) {
  #portfolio li {
    grid-template-columns: 1fr;
    grid-template-areas: "title" "image" "data" "copy" "link";
  }
}

@media (min-width: 1200px) {
  #portfolio li {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title image" "data image" "data image" "copy copy" ". link";
  }
}

#portfolio li a {
  grid-area: link;
  text-align: right;
}

#portfolio li img {
  border-radius: 0.4rem;
  grid-area: image;
  width: 100%;
}

#portfolio li dl {
  grid-area: data;
  margin: 0;
  width: 100%;
}

#portfolio li dl dd {
  margin-left: 1.6rem;
}

#portfolio li dl dt {
  color: #ccc;
}

#portfolio li h3 {
  grid-area: title;
  margin: 0;
}

#portfolio li p {
  grid-area: copy;
  margin-bottom: 0;
}

.hidden {
  display: none;
}

#banner {
  background: #3bc27c;
  bottom: -0.75rem;
  display: inline-block;
  left: 1rem;
  margin: 0 auto 1.6rem;
  position: fixed;
  right: 1rem;
  text-decoration: none;
  z-index: 3;
}

@media (min-width: 900px) {
  #banner {
    bottom: 0;
    left: 0;
    position: relative;
    right: 0;
  }
}

#banner:focus, #banner:hover {
  background: #6fdca4;
}

#banner:focus:after, #banner:hover:after {
  border-top-color: #6fdca4;
}

.grecaptcha-badge {
  visibility: hidden;
}
/*# sourceMappingURL=contact.512bcfe2.css.map */
