@import 'mixins.scss';
@import 'typography.scss';
@import 'layout.scss';
@import 'form.scss';
@import 'footer.scss';
@import 'navigation.scss';
@import 'sidebar.scss';
@import 'resume.scss';
@import 'portfolio.scss';

.hidden {
    display: none;
}

#banner {
    @extend button;
    background: #3bc27c;
    bottom: -0.75rem;
    display: inline-block;
    left: 1rem;
    margin: 0 auto 1.6rem;
    position: fixed;
    right: 1rem;
    text-decoration: none;
    z-index: 3;

    @include for-tablet-landscape-up {
        bottom: 0;
        left: 0;
        position: relative;
        right: 0;
    }

    &:focus,
    &:hover {
        background: #6fdca4;

        &:after {
            border-top-color: #6fdca4;
        }
    }
}

.grecaptcha-badge {
    visibility: hidden;
}
