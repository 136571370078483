footer {
    border-top: 1px solid lighten(#081c36, 10%);
    font-size: 0.85rem;
    padding-top: 1.6rem;
    text-align: center;

    span {
        color: #f1c232;
    }
}
